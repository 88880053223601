body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

.buildLabel {
  color: black;
  opacity: 0.8;
  padding-left: 20px;
}

.buildLabelInvisible {
  color: black;
  opacity: 0;
  padding-left: 20px;
}

.ccp-wrapper {
  /* border-style: solid; */
  /* border-color: grey; */
  /* border-width: 1px; */
  /* padding: 1px; */
  /* display: inline-block; */
  width: 334px !important;
  padding-right: 0 !important;
}
/* #ccpFrame { */
/* padding-top: 1rem;*/
/* } */
.collapsed {
  width: 1px !important;
  height: 1px !important;
}

.screenpop-wrapper {
  padding: 0 !important;
}

.screenpop-wrapper image {
  width: 100%;
}

.ccp-frame {
  height: 512px;
  width: 320px;
}

.ccp-frame.collapsed {
  padding-top: 15px;
}

.ccp-frame iframe,
.screenpop-wrapper iframe {
  margin-left: auto;
  margin-right: auto;
  border: 0;
}

.ccp-bottom-panel {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.center {
  text-align: center;
}

.ui.button {
  border-radius: 0;
}

CallInfo .CallInfo,
secure-payment .payment,
CallInfo .call-list {
  width: 200px;
  border: 1px solid silver;
  border-radius: 12px;
  background: white;
  vertical-align: top;
  margin-left: 12px;
  text-align: center;
  padding: 0px;
}

.CallInfo,
.payment,
.call-list {
  width: 200px;
  border: 1px solid silver;
  /*border-radius: 12px;*/
  background: white;
  vertical-align: top;
  margin-left: 12px;
  text-align: center;
  padding: 0px;
}

.card-jmb {
  background-color: #333;
  width: 100% !important;
}

.card-jmb.ui.card > .content.header {
  padding: 0.9em;
}
.card-jmb.ui.card > .content.body {
  height: 250px;
  overflow: auto;
}

.card-jmb.ui.card > .content.header {
  background-color: #117e26;
}

.card-jmb.ui.card > .content.header.national {
  background-color: #00703c;
}

.card-jmb.ui.card > .content.header.enterprise {
  background-color: #231f20;
}

.card-jmb.ui.card > .content.header.ford {
  background-color: #000049;
}

.card-jmb.ui.card > .content.header.lincoln {
  background-color: #231f20;
}

.card-jmb.ui.card > .content.header.alamo {
  background-color: #0161a9;
}
.card-jmb.ui.card > .content.header.ean {
  background-color: #778899;
}
.card-jmb.ui.card > .content.header.gus {
  background-color: #002858;
}

.title .header {
  color: #117e26 !important;
  width: 100% !important;
}

.title {
  color: #117e26 !important;
  width: 100% !important;
}

.title .header.national {
  color: #00703c !important;
  width: 100% !important;
}

.title.national {
  color: #00703c !important;
  width: 100% !important;
}

.title .header.enterprise {
  color: #231f20 !important;
  width: 100% !important;
}

.title .header.ford {
  color: #000049 !important;
  width: 100% !important;
}

.title .header.lincoln {
  color: #231f20 !important;
  width: 100% !important;
}

.title.enterprise {
  color: #231f20 !important;
  width: 100% !important;
}

.title.ford {
  color: #000049 !important;
  width: 100% !important;
}

.title.lincoln {
  color: #231f20 !important;
  width: 100% !important;
}

.title .header.alamo {
  color: #0161a9 !important;
  width: 100% !important;
}

.title.alamo {
  color: #0161a9 !important;
  width: 100% !important;
}

.title .header.ean {
  color: #778899 !important;
  width: 100% !important;
}

.title.ean {
  color: #778899 !important;
  width: 100% !important;
}

.title .header.gus {
  color: #002858 !important;
  width: 100% !important;
}

.title.gus {
  color: #002858 !important;
  width: 100% !important;
}

.card-jmb.ui.card > .content > .header {
  color: #fff;
  font-family: AmazonEmber, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.button.alamo {
  background-color: #0161a9 !important;
  color: #dad419;
}

.button.ean {
  background-color: #778899 !important;
  color: #ffffff;
}

.button.gus {
  background-color: #002858 !important;
  color: #ffffff;
}

.button.national {
  background-color: #00703c;
  color: #ffffff;
}

.button.enterprise {
  background-color: #231f20 !important;
  color: #ffffff;
}

.button.ford {
  background-color: #000049 !important;
  color: #ffffff;
}

.button.lincoln {
  background-color: #231f20 !important;
  color: #ffffff;
}

.card-jmb.ui.card > .content.alamo > .header {
  color: #dad419;
  font-family: AmazonEmber, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.card-jmb.ui.card > .content.ean > .header {
  color: #ffffff;
  font-family: AmazonEmber, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.card-jmb.ui.card > .content.gus > .header {
  color: #ffffff;
  font-family: AmazonEmber, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.call-icon {
  margin-top: 9px !important;
}
.call-icon:hover {
  cursor: pointer;
}

#headerTabs .ui.menu {
  background-color: #117e26;
  margin-bottom: 0;
  float: right;
  margin-right: 6px;
}
#headerTabs .ui.attached {
  border: 0;
  padding: 0;
  margin-top: 10px;
}
#headerTabs .ui.menu .active.item,
#headerTabs .ui.menu .active.item:hover {
  background-color: #fff !important;
  color: #117e26 !important;
  border-left: 0;
  border-radius: 8px 8px 0px 0px;
}

#headerTabs .ui.menu .item,
#headerTabs .ui.menu .item:hover {
  background-color: #117e26;
  color: #fff;
  padding: 0.3125rem 0.625rem;
  margin: 0 3px;
  border-left: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0px 0px 0px 0px;
  margin: 0;
  min-width: 40px;
}
#headerTabs .ui.menu .item .cornerLeft {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  background-color: inherit;
  right: 100%;
  overflow: hidden;
  z-index: -1;
}
#headerTabs .ui.menu .item .cornerLeft::after {
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #117e26;
  left: 0px;
  border-radius: 0px 0px 6px;
}
#headerTabs .ui.menu .active.item .cornerLeft {
  z-index: 2;
}
#headerTabs .ui.menu .active.item .cornerRight {
  z-index: 2;
}
#headerTabs .ui.menu .item .cornerRight {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  left: 100%;
  overflow: hidden;
  background-color: inherit;
  z-index: -1;
}
#headerTabs .ui.menu .item .cornerRight::after {
  right: 0px;
  border-radius: 0px 0px 0px 6px;
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #117e26;
}
#headerTabs .ui.menu .item::before {
  width: 0;
}
#headerTabs .segment {
  width: 100%;
  margin: 0;
  border: none;
  max-height: 290px;
  overflow: auto;
}

#headerTabsGeneric .ui.menu {
  background-color: #181818;
  margin-bottom: 0;
  float: right;
  margin-right: 6px;
}
#headerTabsGeneric .ui.attached {
  border: 0;
  padding: 0;
  margin-top: 10px;
}
#headerTabsGeneric .ui.menu .active.item,
#headerTabsGeneric .ui.menu .active.item:hover {
  background-color: #fff !important;
  color: #181818 !important;
  border-left: 0;
  border-radius: 8px 8px 0px 0px;
}

#headerTabsGeneric .ui.menu .item,
#headerTabsGeneric .ui.menu .item:hover {
  background-color: #4a4a4a;
  color: #fff;
  padding: 0.3125rem 0.625rem;
  margin: 0 3px;
  border-left: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0px 0px 0px 0px;
  margin: 0;
  min-width: 40px;
}
#headerTabsGeneric .ui.menu .item .cornerLeft {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  background-color: inherit;
  right: 100%;
  overflow: hidden;
  z-index: -1;
}
#headerTabsGeneric .ui.menu .item .cornerLeft::after {
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #4a4a4a;
  left: 0px;
  border-radius: 0px 0px 6px;
}
#headerTabsGeneric .ui.menu .active.item .cornerLeft {
  z-index: 2;
}
#headerTabsGeneric .ui.menu .active.item .cornerRight {
  z-index: 2;
}
#headerTabsGeneric .ui.menu .item .cornerRight {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  left: 100%;
  overflow: hidden;
  background-color: inherit;
  z-index: -1;
}
#headerTabsGeneric .ui.menu .item .cornerRight::after {
  right: 0px;
  border-radius: 0px 0px 0px 6px;
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #4a4a4a;
}
#headerTabsGeneric .ui.menu .item::before {
  width: 0;
}
#headerTabsGeneric .segment {
  width: 100%;
  margin: 0;
  border: none;
  max-height: 500px;
  overflow: auto;
}

#tabsCard {
  background-color: #117e26;
}
#tabsCard .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCard .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCard .content {
  padding: 0;
}

#tabsCardGeneric {
  background-color: #4a4a4a;
}
#tabsCardGeneric .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCardGeneric .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCardGeneric .content {
  padding: 0;
}

.ui.feed {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  font-size: 12px;
}
.ui.label {
  margin: 0 0 0 10px !important;
}
.ui.feed .meta {
  margin: 0 !important;
}
.location-icon {
  float: right;
  color: #117e26;
  margin-top: -20px !important;
}
.location-icon:hover {
  cursor: pointer;
}
.card.title > .content {
  padding: 0.6em;
}
.card.title > .content .ui.header {
  margin: 0;
}
#dispositionAccordion {
  background: #fff !important;
  float: left !important;
  margin: 0 !important;
  padding: 5px;
  max-height: 300px;
  overflow: auto;
}

#rescheduleDatePicker {
  padding-bottom: 20em;
}

#dispositionAccordion .item {
  background: #fff !important;
}

#rescheduleDatePicker input {
  padding: 6px 10px 5px 25px;
  width: 120%;
}
.dispositionButton {
  float: right;
  margin-top: 5px !important;
}
.loader {
  margin-top: 8px !important;
}
#ccpPanel .card {
  width: 100%;
}
/* .row { */
/*padding: 0!important;*/
/* } */

.callIconBox {
  position: relative;
}
.callIconBox .callTypeIcon {
  font-size: 1em !important;
  margin: 0;
  padding: 0 !important;
  top: 20px;
  right: -10px;
  position: absolute;
}
.dispositionRow {
  margin-top: 20px !important;
}
.callerIcons {
  /*margin-top: 50px;*/
  padding-left: 15px !important;
}
.callerIcons button {
  margin: 5px !important;
}
.callerIcons .ui.basic.label {
  border: none;
  font-size: 10px;
  margin: 0px !important;
  padding: 0;
}

.tableContainer {
  max-height: 155px;
  overflow: auto;
}

.infoColumn {
  width: 500px !important;
  min-width: 334px;
  padding-right: 0;
  overflow-wrap: break-word;
}

.call-logger-table table {
  margin: 0 !important;
}

.call-logger-table ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.call-logger-table ::-webkit-scrollbar-track {
  border-radius: 0 5px 5px 0;
}

/* Handle */
.call-logger-table ::-webkit-scrollbar-thumb {
  border-radius: 0 5px 5px 0;
}